import React, { useState, useEffect, useRef } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import clock from './assets/clock_2784459 (1) 1.png';
import map from './assets/2913974 1.png';
import apple from './assets/file-icons_apple.png';
import instagram from './assets/pngwing.com (20).png'; 
import head from './assets/headset_4315210 1.png';
import snapchat from './assets/pngwing.com.snapchat (4).png';
import image from './LindkPlusAssets/plusbg.jpg';
import whatsapp from './assets/pngwing.com (19).png'; 
import phone from './assets/Vector (5).png';
import male from './assets/support_999094 1.png'; 
import female from './assets/secretary_8291296 1.png';
import logo from './LindkPlusAssets/lighting_design+_logo-removebg-preview.png';
import manager from './assets/manager_345736 1.png';
import goma from './LindkPlusAssets/Powered_By_GOMA.593c8881dd98b7dcda9b.png'
import google_play from './assets/google-play-icon.png' 

function useOutsideClick(ref, callback) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

const LinksPlus = () => {
  const [open, setOpen] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  const schedule = [
    { day: "Sat", hours: "9:00 AM - 10:00 PM" },
    { day: "Sun", hours: "9:00 AM - 10:00 PM" },
    { day: "Mon", hours: "9:00 AM - 10:00 PM" },
    { day: "Tue", hours: "9:00 AM - 10:00 PM" },
    { day: "Wed", hours: "9:00 AM - 10:00 PM" },
    { day: "Thu", hours: "9:00 AM - 10:00 PM" },
    { day: "Fri", hours: "4:00 PM - 10:00 PM" },
  ];

  const contacts = [
    { image: male, name: "Saad Dahan (Sales)", phone_number: "+966530158338", id: "1" },
    { image: manager, name: "Anas Al-Akeel (Manager)", phone_number: "+966580709010", id: "2" },
  ];

  const toggleOpen = () => setOpen(!open);
  const toggleOpenContact = () => setOpenContact(!openContact);

  return (
    <div className='w-screen h-screen flex flex-col md:flex-row-reverse'>
      <div className='bg-[#232323] fixed w-screen h-screen top-0 bottom-0 right-0 left-0 -z-10'></div>

      <div className='md:w-3/5 w-full h-1/3 md:h-full'>
        <div style={{
          backgroundImage: `url(${image})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '100%',
          minHeight: '33vh',
          '@media (max-width: 768px)': {
            minHeight: '50vh'
          }
        }}></div>
      </div>

      <div className='md:w-2/5 w-full h-full bg-[#232323] text-white relative'>
        <div className='flex flex-col justify-center items-center py-4'>
          <img className='w-36 h-36 -mt-6' src={logo} alt='Logo' />
        </div>

        <div className='flex flex-col gap-3 justify-center items-center md:h-4/5 h-full w-full -mt-14'>
          {/* Opening Times */}
          <DropDownButton
            label="Opening At"
            icon={clock}
            isOpen={open}
            onClick={toggleOpen}
            items={schedule}
            renderItem={(item) => <li key={item.day}>{item.day}: {item.hours}</li>}
          />

          {/* Instagram */}
          <ExternalLinkButton
            href='https://www.instagram.com/lightingdesignplus?igsh=MXJzM2Y1ejBsOXlraQ%3D%3D&utm_source=qr'
            label='Instagram'
            icon={instagram}
          />

          {/* Snapchat */}
          <ExternalLinkButton
            href='https://snapchat.com/t/yYKiiuee'
            label='Snapchat'
            icon={snapchat}
          />

          {/* Contact Us */}
          <DropDownButton
            label="Contact Us"
            icon={head}
            isOpen={openContact}
            onClick={toggleOpenContact}
            items={contacts}
            renderItem={(item) => (
              <ContactItem key={item.id} contact={item} />
            )}
          />

          {/* Download App */}
          <ExternalLinkButton
            href='https://apps.apple.com/app/id6464472171'
            label='Download the app here'
            icon={apple}
          />
          <ExternalLinkButton
          href='https://play.google.com/store/apps/details?id=com.makane.lightingdesignksa'
          label='Download the app here'
          icon={google_play}
      />
          {/* Location */}
          <ExternalLinkButton
            href='https://maps.app.goo.gl/91FG9VRrnh5KAcRi6?g_st=com.google.maps.preview.copy'
            label='Location'
            icon={map}
          />
        </div>

        <a href='https://social.gomaksa.com/omar' className="w-full py-2 text-center text-white bg-[#232323] flex justify-center">
        <div className='flex'>
    
<div  className="flex justify-center">
<img 
    src={goma} // Replace with your image path
    alt="Description" // Provide a meaningful description
    style={{ width: '150px', height: 'auto' }} // Adjust width as needed
  className="ml-1"
/>
</div>
</div>

</a>
      </div>
    </div>
  );
};

const DropDownButton = ({ label, icon, isOpen, onClick, items, renderItem }) => {
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => {
    if (isOpen) onClick(); 
  });

  return (
    <div ref={dropdownRef} className='w-full relative'>
      <div className='flex justify-center items-center'>
        <button
          className="rounded-lg bg-[#2E2E2EEB] w-[90%] flex justify-between items-center h-14 px-3"
          onClick={onClick}
        >
          <div className='flex flex-row items-center gap-3'>
            <img className='w-7 h-7' src={icon} alt='' />
            <h2 className='text-md text-[#fff]'>{label}</h2>
          </div>
          <IoIosArrowDown className={`w-4 h-4 ${isOpen ? 'rotate-180' : ''}`} />
        </button>
      </div>
      {isOpen && (
        <div className="z-50 bg-[#2E2E2EEB] rounded-lg shadow font-semibold text-center flex justify-center w-[80%] absolute left-10">
          <ul className="py-2 text-sm">
            {items.map(renderItem)}
          </ul>
        </div>
      )}
    </div>
  );
};

const ContactItem = ({ contact }) => (
  <div className='bg-[#2E2E2EEB] p-1 pl-5 items-center rounded-lg text-xs'>
    <div className='flex flex-row items-center gap-3 -ml-3 mb-3'>
      <img className='w-5 h-5 ml-1' src={contact.image} alt='' />
      <h2 className='text-[14px] text-[#fff]'>{contact.name}</h2>
    </div>
    <div className='flex'>
      <div className='border-l h-12 -mt-1.5'></div>
      <div>
        <div className='flex items-center' onClick={() => window.open(`tel:${contact.phone_number}`)}>
          <span className='border-b border-white w-5'></span>
          <img className='w-3 ml-2' src={phone} alt='' />
          <button className='text-xs text-[#B7B7B7] flex justify-end ml-2'>{contact.phone_number}</button>
        </div>
        <div className='flex items-center mt-3.5'>
          <span className='border-b border-white w-5'></span>
          <img className='w-5 h-5 ml-1' src={whatsapp} alt='' />
          <button
            className='text-xs flex justify-end ml-1 text-[#B7B7B7]'
            onClick={() => window.open(`https://wa.me/${contact.phone_number}`)}
          >
            Click Here
          </button>
        </div>
      </div>
    </div>
  </div>
);

const ExternalLinkButton = ({ href, label, icon }) => (
  <div className='w-full relative'>
    <div className='flex justify-center items-center'>
      <a href={href} target="_blank" rel="noopener noreferrer" className="rounded-lg bg-[#2E2E2EEB] w-[90%] flex items-center h-14 px-3">
        <div className='flex flex-row items-center gap-3'>
          <img className='w-7 h-7' src={icon} alt='' />
          <h2 className='text-md text-[#fff]'>{label}</h2>
        </div>
      </a>
    </div>
  </div>
);

export default LinksPlus;
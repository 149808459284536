import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Links from './Links';
import LinksPlus from './LinksPlus'; // Make sure LinksPlus is imported

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Links />} />
          <Route path="/plus" element={<LinksPlus />} />
        </Routes>
      </div>
    </Router>
  );
}
  
export default App;